import axiosInstance from '../axiosConfig';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api/service-provider/qualification`;

const qualificationService = {
  // List all qualifications
  list: async (accessToken) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  // Add new qualification
  create: async (accessToken, qualificationData) => {
    try {
      // Create FormData object to handle file upload
      const formData = new FormData();
      
      // Append text fields
      formData.append('degree', qualificationData.degree);
      formData.append('institution', qualificationData.institution);
      formData.append('year_start', qualificationData.yearStart);
      formData.append('year_end', qualificationData.yearEnd);
      formData.append('description', qualificationData.description);
      
      // Append verification URL if exists
      if (qualificationData.verificationUrl) {
        formData.append('verification_url', qualificationData.verificationUrl);
      }

      // Append certificate file if exists
      if (qualificationData.certificateFile) {
        formData.append('certificate', qualificationData.certificateFile);
      }

      // Append institution logo file if exists
      if (qualificationData.institutionLogoFile) {
        formData.append('institution_logo', qualificationData.institutionLogoFile);
      }

      const response = await axiosInstance.post(`${API_URL}/`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  // Update qualification
  update: async (accessToken, id, qualificationData) => {
    try {
      const formData = new FormData();
      
      // Append text fields if they exist
      if (qualificationData.degree) formData.append('degree', qualificationData.degree);
      if (qualificationData.institution) formData.append('institution', qualificationData.institution);
      if (qualificationData.yearStart) formData.append('year_start', qualificationData.yearStart);
      if (qualificationData.yearEnd) formData.append('year_end', qualificationData.yearEnd);
      if (qualificationData.description) formData.append('description', qualificationData.description);
      if (qualificationData.verificationUrl) {
        formData.append('verification_url', qualificationData.verificationUrl);
      }

      // Append certificate file if exists
      if (qualificationData.certificateFile) {
        formData.append('certificate', qualificationData.certificateFile);
      }

      const response = await axiosInstance.patch(`${API_URL}/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  // Delete qualification
  delete: async (accessToken, id) => {
    try {
      const response = await axiosInstance.delete(`${API_URL}/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      return response.status === 204; // Returns true if deletion was successful
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  // Get single qualification
  get: async (accessToken, id) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
};

export default qualificationService;
