import React from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Utils from 'services/Utils';

const Navbar = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation()
  const timeZoneInfo = Utils.getLocalTimeZoneInfo(); // Call the function once

  const GetTimeZone = `${timeZoneInfo.timeZone} - ${timeZoneInfo.abbreviation}`; // Create the string

  return (
    <Flex
      as="nav"
      align="center"
      px={'10px'}
      borderBottom="1px"
      borderColor="gray.200"
    >
      <Link
        as={RouterLink}
        to="/admin/settings/profile"
        fontWeight={'500'}
        fontSize={'18px'}
        paddingX={4}
        paddingY={2}
        color={pathname === '/admin/settings/profile' ? '#FF6666' : 'gray.500'}
        borderBottom={pathname === '/admin/settings/profile' ? '2px solid #FF6666' : 'none'}
        _hover={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }} // Removes the focus outline that might appear
      >
        {t("admin_my_profile")}
      </Link>
      <Link
        as={RouterLink}
        fontWeight={'500'}
        fontSize={'18px'}
        to="/admin/settings/availability"
        paddingX={4}
        paddingY={2}
        color={pathname === '/admin/settings/availability' ? '#FF6666' : 'gray.500'}
        borderBottom={pathname === '/admin/settings/availability' ? '2px solid #FF6666' : 'none'}
        _hover={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }} // Removes the focus outline that might appear
      >
        {t("admin_availability")}
      </Link>
      <Link
        as={RouterLink}
        fontWeight={'500'}
        fontSize={'18px'}
        to="/admin/settings/education"
        paddingX={4}
        paddingY={2}
        color={pathname === '/admin/settings/education' ? '#FF6666' : 'gray.500'}
        borderBottom={pathname === '/admin/settings/education' ? '2px solid #FF6666' : 'none'}
        _hover={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        {t("admin_education")}
      </Link>
      <Text
        position='absolute'
        right='1rem'
        color='gray.500'
      > {GetTimeZone}</Text>
      {/* <Link
        as={RouterLink}
        fontWeight={'500'}
        fontSize={'18px'}
        to="/admin/settings/unavailability"
        paddingX={4}
        paddingY={2}
        color={pathname === '/admin/settings/unavailability' ? '#FF6666' : 'gray.500'}
        borderBottom={pathname === '/admin/settings/unavailability' ? '2px solid #FF6666' : 'none'}
        _hover={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }} // Removes the focus outline that might appear
      >
        Unavailability
      </Link>

      <Link
        as={RouterLink}
        fontWeight={'500'}
        fontSize={'18px'}
        to="/admin/settings/configurations"
        paddingX={4}
        paddingY={2}
        color={pathname === '/admin/settings/configurations' ? '#FF6666' : 'gray.500'}
        borderBottom={pathname === '/admin/settings/configurations' ? '2px solid #FF6666' : 'none'}
        _hover={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }} // Removes the focus outline that might appear
      >
        {t("admin__nav_configurations")}
      </Link> */}
    </Flex>
  );
};

export default Navbar;
