import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormErrorMessage,
  Image,
  Center,
  Spinner,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon, AttachmentIcon } from '@chakra-ui/icons';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import qualificationService from 'services/qulification_service';
import { FaGraduationCap } from "react-icons/fa";

const Education = ({ accessToken }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isDeleteOpen, 
    onOpen: onDeleteOpen, 
    onClose: onDeleteClose 
  } = useDisclosure();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [educations, setEducations] = useState([]);
  const [currentEducation, setCurrentEducation] = useState(null);
  const cancelRef = React.useRef();
  const [formErrors, setFormErrors] = useState({
    degree: '',
    institution: '',
    yearStart: '',
    yearEnd: ''
  });
  const [certificateFile, setCertificateFile] = useState(null);
  const [certificatePreview, setCertificatePreview] = useState(null);
  const fileInputRef = React.useRef();
  const [institutionLogoFile, setInstitutionLogoFile] = useState(null);
  const [institutionLogoPreview, setInstitutionLogoPreview] = useState(null);
  const institutionLogoInputRef = React.useRef();

  // Fetch qualifications on component mount
  useEffect(() => {
    fetchQualifications();
  }, []);

  const fetchQualifications = async () => {
    try {
      const data = await qualificationService.list(accessToken);
      setEducations(data);
    } catch (error) {
      toast({
        title: t("education.error_loading_qualifications"),
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    } finally {
      setIsInitialLoading(false);
    }
  };

  const handleAdd = () => {
    setCurrentEducation(null);
    onOpen();
  };

  const handleEdit = (education) => {
    setCurrentEducation(education);
    onOpen();
  };

  const handleDelete = (education) => {
    setCurrentEducation(education);
    onDeleteOpen();
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      await qualificationService.delete(accessToken, currentEducation.id);
      await fetchQualifications();
      
      toast({
        title: t("education.education_deleted"),
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: t("education.error_deleting_education"),
        description: error.message,
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      onDeleteClose();
      resetForm();
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    const currentYear = new Date().getFullYear();
    
    // Degree validation
    const degree = formData.get('degree');
    if (!degree.trim()) {
      errors.degree = t('education.degree_required');
    } else if (degree.length < 2) {
      errors.degree = t('education.degree_min_length');
    } else if (degree.length > 100) {
      errors.degree = t('education.degree_max_length');
    }

    // Institution validation
    const institution = formData.get('institution');
    if (!institution.trim()) {
      errors.institution = t('education.institution_required');
    } else if (institution.length < 2) {
      errors.institution = t('education.institution_min_length');
    } else if (institution.length > 100) {
      errors.institution = t('education.institution_max_length');
    }

    // Year validations
    const yearStart = parseInt(formData.get('yearStart'));
    const yearEnd = parseInt(formData.get('yearEnd'));

    if (!yearStart) {
      errors.yearStart = t('education.start_year_required');
    } else if (yearStart < 1900 || yearStart > currentYear) {
      errors.yearStart = t('education.start_year_invalid');
    }

    if (!yearEnd) {
      errors.yearEnd = t('education.end_year_required');
    } else if (yearEnd < yearStart) {
      errors.yearEnd = t('education.end_year_before_start');
    } else if (yearEnd > currentYear + 10) {
      errors.yearEnd = t('education.end_year_too_far');
    }

    return errors;
  };

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validTypes.includes(file.type)) {
      toast({
        title: t("education.invalid_certificate_type"),
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
      return false;
    }

    if (file.size > maxSize) {
      toast({
        title: t("education.certificate_size_exceeded"),
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && validateFile(file)) {
      setCertificateFile(file);
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCertificatePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setCertificatePreview(null);
      }
    }
  };

  const handleInstitutionLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInstitutionLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setInstitutionLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file && validateFile(file)) {
      setCertificateFile(file);
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCertificatePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setCertificatePreview(null);
      }
    }
  };

  const resetForm = () => {
    setCurrentEducation(null);
    setCertificateFile(null);
    setCertificatePreview(null);
    setInstitutionLogoFile(null);
    setInstitutionLogoPreview(null);
    setFormErrors({
      degree: '',
      institution: '',
      yearStart: '',
      yearEnd: ''
    });
  };

  const handleModalClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    
    // Validate form
    const errors = validateForm(formData);
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setIsLoading(true);

    try {
      const qualificationData = {
        degree: formData.get('degree'),
        institution: formData.get('institution'),
        yearStart: formData.get('yearStart'),
        yearEnd: formData.get('yearEnd'),
        description: formData.get('description'),
        verificationUrl: formData.get('certificate'),
        certificateFile: certificateFile,
        institutionLogoFile: institutionLogoFile
      };

      if (currentEducation) {
        await qualificationService.update(accessToken, currentEducation.id, qualificationData);
      } else {
        await qualificationService.create(accessToken, qualificationData);
      }

      // Refresh the list
      await fetchQualifications();
      
      // Show success toast
      toast({
        title: currentEducation 
          ? t("education.education_updated")
          : t("education.education_added"),
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: true,
      });

      // Close modal and reset form
      handleModalClose();
    } catch (error) {
      toast({
        title: currentEducation 
          ? t("education.error_updating_education")
          : t("education.error_adding_education"),
        description: error.message,
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderCertificatePreview = (education) => {
    if (!education.certificateFile) return null;

    if (education.certificateFile.type.startsWith('image/')) {
      return (
        <Image
          src={education.certificateFile.preview}
          alt="Certificate"
          maxH="100px"
          objectFit="contain"
          mt={2}
        />
      );
    }

    return (
      <Button
        leftIcon={<AttachmentIcon />}
        size="sm"
        variant="outline"
        mt={2}
        onClick={() => window.open(education.certificate, '_blank')}
      >
        {education.certificateFile.name}
      </Button>
    );
  };

  return (
    <Box p={4}>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="red"
        onClick={handleAdd}
        mb={4}
        isDisabled={isInitialLoading}
      >
        {t("education.add_education")}
      </Button>

      {isInitialLoading ? (
        <Center py={8}>
          <Spinner size="xl" color="red.500" />
        </Center>
      ) : educations.length === 0 ? (
        <Center py={8}>
          <Text color="gray.500">{t("education.no_qualifications")}</Text>
        </Center>
      ) : (
        <VStack spacing={4} align="stretch">
          {educations.map((education) => (
            <Box 
              key={education.id}
              p={0}
              shadow="md"
              borderWidth="1px"
              borderRadius="lg"
              bg="white"
              overflow="hidden"
              transition="all 0.2s"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "lg",
                "& .institution-logo": {
                  transform: "scale(1.05)",
                }
              }}
            >
              <Flex>
                <Box 
                  w="120px" 
                  maxHeight="100%"
                  bg="gray.100"
                  position="relative"
                  overflow="hidden"
                >
                  {education.institution_logo ? (
                    <Image
                      src={education.institution_logo}
                      alt={`${education.institution} logo`}
                      w="100%"
                      h="100%"
                      objectFit="cover"
                      className="institution-logo"
                      transition="transform 0.2s"
                    />
                  ) : (
                    <Center h="100%" w="100%">
                      <Icon 
                        as={FaGraduationCap} 
                        w={10} 
                        h={10} 
                        color="gray.400"
                        className="institution-logo"
                        transition="transform 0.2s"
                      />
                    </Center>
                  )}
                </Box>

                <Box flex={1} p={5}>
                  <Stack direction="row" justifyContent="space-between">
                    <Box flex={1}>
                      <Text fontSize="xl" fontWeight="bold">{education.degree}</Text>
                      <Text>{education.institution}</Text>
                      <Text color="gray.600">
                        {education.year_start} - {education.year_end}
                      </Text>
                      <Text mt={2}>{education.description}</Text>
                      {education.certificate && (
                        <Button
                          leftIcon={<AttachmentIcon />}
                          size="sm"
                          variant="outline"
                          mt={2}
                          onClick={() => window.open(education.certificate, '_blank')}
                        >
                          {t("education.view_certificate")}
                        </Button>
                      )}
                    </Box>
                    <Stack direction="row">
                      <IconButton
                        icon={<EditIcon />}
                        onClick={() => handleEdit(education)}
                        aria-label="Edit"
                        colorScheme="blue"
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => handleDelete(education)}
                        aria-label="Delete"
                        colorScheme="red"
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Flex>
            </Box>
          ))}
        </VStack>
      )}

      {/* Edit/Add Modal */}
      <Modal 
        isOpen={isOpen} 
        onClose={handleModalClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentEducation ? t("education.edit_education") : t("education.add_education")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl isRequired isInvalid={!!formErrors.degree}>
                  <FormLabel>{t("education.degree")}</FormLabel>
                  <Input
                    name="degree"
                    defaultValue={currentEducation?.degree}
                    placeholder={t("education.enter_degree")}
                  />
                  <FormErrorMessage>{formErrors.degree}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!formErrors.institution}>
                  <FormLabel>{t("education.institution")}</FormLabel>
                  <Input
                    name="institution"
                    defaultValue={currentEducation?.institution}
                    placeholder={t("education.enter_institution")}
                  />
                  <FormErrorMessage>{formErrors.institution}</FormErrorMessage>
                </FormControl>

                <Stack direction="row" width="100%">
                  <FormControl isRequired isInvalid={!!formErrors.yearStart}>
                    <FormLabel>{t("education.start_year")}</FormLabel>
                    <Input
                      name="yearStart"
                      type="number"
                      defaultValue={currentEducation?.yearStart}
                      placeholder="YYYY"
                      min="1900"
                      max={new Date().getFullYear()}
                    />
                    <FormErrorMessage>{formErrors.yearStart}</FormErrorMessage>
                  </FormControl>

                  <FormControl isRequired isInvalid={!!formErrors.yearEnd}>
                    <FormLabel>{t("education.end_year")}</FormLabel>
                    <Input
                      name="yearEnd"
                      type="number"
                      defaultValue={currentEducation?.yearEnd}
                      placeholder="YYYY"
                      min={formErrors.yearStart || "1900"}
                      max={new Date().getFullYear() + 10}
                    />
                    <FormErrorMessage>{formErrors.yearEnd}</FormErrorMessage>
                  </FormControl>
                </Stack>

                <FormControl>
                  <FormLabel>{t("education.description")}</FormLabel>
                  <Textarea
                    name="description"
                    defaultValue={currentEducation?.description}
                    placeholder={t("education.enter_description")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{t("education.certificate_link")}</FormLabel>
                  <Input
                    name="certificate"
                    defaultValue={currentEducation?.certificate}
                    placeholder={t("education.enter_certificate_link")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{t("education.upload_certificate")}</FormLabel>
                  <Box
                    border="2px dashed"
                    borderColor="gray.300"
                    borderRadius="md"
                    p={4}
                    textAlign="center"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current?.click()}
                    cursor="pointer"
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept=".pdf,.png,.jpg,.jpeg"
                      style={{ display: 'none' }}
                    />
                    <Text>{t("education.drag_drop_certificate")}</Text>
                    {certificateFile && (
                      <VStack mt={2}>
                        <Text>{certificateFile.name}</Text>
                        {certificatePreview && (
                          <Image
                            src={certificatePreview}
                            alt="Certificate Preview"
                            maxH="200px"
                            objectFit="contain"
                          />
                        )}
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCertificateFile(null);
                            setCertificatePreview(null);
                          }}
                        >
                          {t("education.remove_certificate")}
                        </Button>
                      </VStack>
                    )}
                  </Box>
                </FormControl>

                <FormControl>
                  <FormLabel>{t("education.upload_institution_logo")}</FormLabel>
                  <Box
                    border="2px dashed"
                    borderColor="gray.300"
                    borderRadius="md"
                    p={4}
                    textAlign="center"
                    onClick={() => institutionLogoInputRef.current?.click()}
                    cursor="pointer"
                  >
                    <input
                      type="file"
                      ref={institutionLogoInputRef}
                      onChange={handleInstitutionLogoChange}
                      accept=".png,.jpg,.jpeg"
                      style={{ display: 'none' }}
                    />
                    <Text>{t("education.drag_drop_institution_logo")}</Text>
                    {institutionLogoFile && (
                      <VStack mt={2}>
                        <Text>{institutionLogoFile.name}</Text>
                        {institutionLogoPreview && (
                          <Image
                            src={institutionLogoPreview}
                            alt="Institution Logo Preview"
                            maxH="200px"
                            objectFit="contain"
                          />
                        )}
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setInstitutionLogoFile(null);
                            setInstitutionLogoPreview(null);
                          }}
                        >
                          {t("education.remove_institution_logo")}
                        </Button>
                      </VStack>
                    )}
                  </Box>
                </FormControl>

                <Button 
                  type="submit" 
                  colorScheme="red" 
                  width="100%"
                  isLoading={isLoading}
                >
                  {currentEducation ? t("education.update") : t("education.save")}
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("education.delete_education")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t("education.delete_education_confirmation", { 
                degree: currentEducation?.degree 
              })}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                {t("education.cancel")}
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmDelete}
                ml={3}
                isLoading={isLoading}
              >
                {t("education.delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.auth.accessToken, // Adjust according to your auth state structure
});

export default connect(mapStateToProps)(Education); 