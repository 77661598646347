import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import authService from "services/authService"; // Adjust the import path as needed
import AuthLayout from "layouts/auth";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import LanguageSelector from "components/menu/LanguageSelector";

function ResetPasswordConfirm() {
    const { t } = useTranslation();
    const [uidb64, setUidb64] = useState(""); // Updated field
    const [token, setToken] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState(""); // New field
    const [step, setStep] = useState(1); // 1 for email input, 2 for password reset
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const location = useLocation();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [confirmShowPassword, setConfirmShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    useEffect(() => {
        // Extract uid and token from URL
        const queryParams = new URLSearchParams(location.search);
        const uidFromUrl = queryParams.get('uid');
        const tokenFromUrl = queryParams.get('token');

        if (uidFromUrl && tokenFromUrl) {
            setUidb64(uidFromUrl);
            setToken(tokenFromUrl);
        }
    }, [location.search]);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setConfirmShowPassword(!confirmShowPassword);
    
    const handleNewPasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);

        // Validate password length
        if (value.length < 8) {
            setPasswordError(`${t("passwordLength")}`);
        } else {
            setPasswordError("");
        }

        // Also check if confirm password matches
        if (confirmPassword && value !== confirmPassword) {
            setConfirmPasswordError(`${t("passwordMismatch")}`);
        } else {
            setConfirmPasswordError("");
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);

        // Check if passwords match
        if (newPassword !== value) {
            setConfirmPasswordError(`${t("passwordMismatch")}`);
        } else {
            setConfirmPasswordError("");
        }
    };
    const handlePasswordResetConfirm = async () => {
        // Final validation before submission
        if (newPassword.length < 8) {
            setPasswordError(`${t("passwordLength")}`);
            return;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordError(`${t("passwordMismatch")}`);
            return;
        }

        try {
            await authService.passwordResetConfirm(uidb64, token, newPassword, confirmPassword);
            setMessage(`${t("password_reset_success_msg_small")}`);
            setStep(2); // Reset complete
            setTimeout(() => history.push("/auth/sign-in"), 3000);
        } catch (error) {
            // Extract error message from response if available
            const errorMessage = error.response?.data?.detail || t("password_reset_fail_msg");
            setError(errorMessage);
        }
    };

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";

    return (
        <AuthLayout>
            <DefaultAuth illustrationBackground={illustration} image={illustration}>
                <Flex
                    w="100%"
                    alignItems="start"
                    justifyContent="center"
                    mb={{ base: "30px", md: "60px" }}
                    px={{ base: "25px", md: "0px" }}
                    mt={{ base: "15px", md: "10vh" }}
                    flexDirection="column"
                >
                    <Flex
                        alignItems="start"
                        justifyContent="start"
                        mb={{ base: "28px", md: "60px" }}
                        px={{ base: "20px", md: "0px" }}
                        mt={{ base: "30px", md: "14vh" }}
                    >
                        <LanguageSelector />
                    </Flex>
                    <Box me="auto" maxW={'480px'}>
                        <Heading
                            color={"#252525"}
                            fontSize="48px"
                            mb="10px"
                            fontWeight={"500"}
                        >
                            {t("set_new_password")}
                        </Heading>
                        <Text
                            mb="36px"
                            ms="4px"
                            color="#565656"
                            fontWeight="400"
                            fontSize="26px"
                        >
                            {t("auth__signin__description")}
                        </Text>
                    </Box>
                    <Flex
                        // zIndex="2"
                        direction="column"
                        w={{ base: "100%", md: "100%",lg:'420px' }}
                        maxW="100%"
                        background="transparent"
                        borderRadius="15px"
                        mx={{ base: "auto", lg: "unset" }}
                        me="auto"
                        mb={{ base: "20px", md: "auto" }}
                    >
                        {step === 1 && (
                            <FormControl>
                                <FormLabel
                                    ms="4px"
                                    fontSize="16px"
                                    fontWeight="300"
                                    color={textColor}
                                    display="flex"
                                >
                                    {t("common__password")}
                                    <Text color={"#EE0000"} ml={"1px"}>
                                        *
                                    </Text>
                                </FormLabel>
                                <InputGroup size="md">
                                    <Input
                                        isRequired={true}
                                        fontSize="sm"
                                        placeholder={t("common__password__hint")}
                                        mb="24px"
                                        size="lg"
                                        type={showPassword ? "text" : "password"}
                                        onChange={handleNewPasswordChange}
                                        variant="auth"
                                        borderRadius={"4px"}
                                        bg={'gray.200'}
                                    />
                                    <InputRightElement display="flex" alignItems="center" mt="4px">
                                        <Icon
                                            color={textColorSecondary}
                                            _hover={{ cursor: "pointer" }}
                                            as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                            onClick={togglePasswordVisibility}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                {passwordError && (
                                    <Text color="red.500" fontSize="sm" mb="16px">
                                        {passwordError}
                                    </Text>
                                )}
                                <FormLabel
                                    ms="4px"
                                    fontSize="16px"
                                    fontWeight="300"
                                    color={textColor}
                                    display="flex"
                                >
                                    {t("confirm_new_password")}
                                    <Text color={"#EE0000"} ml={"1px"}>
                                        *
                                    </Text>
                                </FormLabel>
                                <InputGroup size="md">
                                    <Input
                                        isRequired={true}
                                        fontSize="sm"
                                        placeholder={t("confirm_new_password_placeholder")}
                                        mb="24px"
                                        size="lg"
                                        type={confirmShowPassword ? "text" : "password"}
                                        onChange={handleConfirmPasswordChange}
                                        variant="auth"
                                        borderRadius={"4px"}
                                        bg={'gray.200'}
                                    />
                                    <InputRightElement display="flex" alignItems="center" mt="4px">
                                        <Icon
                                            color={textColorSecondary}
                                            _hover={{ cursor: "pointer" }}
                                            as={confirmShowPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                            onClick={toggleConfirmPasswordVisibility}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                {confirmPasswordError && (
                                    <Text color="red.500" fontSize="sm" mb="16px">
                                        {confirmPasswordError}
                                    </Text>
                                )}
                                <Button
                                    color={"white"}
                                    fontSize="20px"
                                    variant="brand"
                                    fontWeight="500"
                                    w="100%"
                                    h="50"
                                    py={"7"}
                                    mb="24px"
                                    onClick={handlePasswordResetConfirm}
                                    bgColor={"#FF6666"}
                                    borderRadius={0}
                                    _hover={{ bg: "#FF8080" }}
                                >
                                    {t("reset_password")}
                                </Button>
                                {error && <Text color="red.500" mt="2">{error}</Text>}
                                {message && <Text color="green.500" mt="2">{message}</Text>}
                            </FormControl>
                        )}

                        {step === 2 && (
                            <Text ms="4px"
                                fontSize="18px"
                                fontWeight="300"
                                color={textColor}
                                textAlign="center">
                                {t("password_reset_success_msg")}
                                <Button
                                    variant="link"
                                    color="#FF6666"
                                    onClick={() => history.push("/auth/sign-in")}
                                >
                                    {t("click_here")}
                                </Button>.
                            </Text>
                        )}
                    </Flex>
                </Flex>
            </DefaultAuth>
        </AuthLayout>
    );
}

export default ResetPasswordConfirm;
