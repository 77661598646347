import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  Stack,
  Divider,
  Menu,
  MenuItem,
  MenuButton,
  Select,
  MenuList,
  TableContainer,
  Textarea
} from '@chakra-ui/react';
import { ChevronDownIcon } from "@chakra-ui/icons"
import { connect, useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
  selectProfessionalProfile
} from "selectors/authSelector";
import LocationModal from '../components/LocationModal'; // Import the LocationModal component
import { useTranslation } from "react-i18next";
import rdvService from 'services/rdvService';
import { loginSuccess } from 'actions/authActions';
import UploadBox from '../components/FileUploader';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { logout } from 'actions/authActions';
import { useHistory } from "react-router-dom";

const Index = ({ user, professional_profile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector(selectAccessToken);
  const association = useSelector(selectAssociation);
  const { t, i18n } = useTranslation()

  const [initialImage, setInitialImage] = useState(professional_profile?.image || '');
  const [initialSignatureImage, setInitialSignatureImage] = useState(professional_profile?.signature_image || '');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    preferredLanguage: '',
    designation: '',
    about: '',
    address: '',
    postalCode: '',
    countryCode: '',
    phoneNumber: '',
    image: '',
    signature_image: '',
    consultation_specialities: '',
  });

  const [hasChanged, setHasChanged] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesIds, setSelectedServicesIds] = useState([]);
  useEffect(() => {
    // console.log("professional_profile", professional_profile);
    setFormData({
      firstName: professional_profile?.first_name,
      lastName: professional_profile?.last_name,
      gender: professional_profile?.gender,
      preferredLanguage: professional_profile?.preferred_language,
      designation: i18n.language === 'fr' ? professional_profile?.designation_fr : professional_profile?.designation,
      about: i18n.language === 'fr' ? professional_profile?.about_fr : professional_profile?.about,
      address: professional_profile?.address,
      postalCode: professional_profile?.postal_code,
      countryCode: professional_profile?.country_code,
      phoneNumber: professional_profile?.phone_number,
      image: professional_profile?.image,
      signature_image: professional_profile?.signature_image,
      consultation_specialities: professional_profile?.consultation_specialities || []
    });
    setInitialImage(professional_profile?.image || '');
    setInitialSignatureImage(professional_profile?.signature_image || '');
    // HandleGetServicesList();
  }, [professional_profile, i18n.language]);

  // Step 1: Create local state
  const [associationData, setAssociationData] = useState(null);

  // Step 2: Get association data from Redux store
  const associationFromStore = useSelector(selectAssociation);

  // Step 3: Use useEffect to set local state and log to console
  useEffect(() => {
    if (associationFromStore) {
      setAssociationData(associationFromStore);
      // console.log('Association Data:', associationFromStore); // Log to console
      HandleGetServicesList(associationFromStore.slug);
    }
  }, [associationFromStore]);  // Run this effect whenever associationFromStore changes


  const HandleGetServicesList = async (slug) => {
    try {
      const res = await rdvService.getServices(accessToken, slug);
      // console.log("services response", res.data.results);

      // Set the selected services only if results exist
      if (Array.isArray(res.data.results)) {
        setSelectedServices(res.data.results);
      } else {
        console.error("No results found in the services response.");
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      // Optionally, you can set an error state or show a notification to the user
      // setError("Failed to fetch services. Please try again later.");
    }
  };

  let toastShown = false;
  useEffect(() => {
    const fetchStatisticsTodayAndWeek = async () => {
      try {
        const res = await rdvService.statistics(accessToken);

        if (res.error) {
          if (res.error.status === 401) {
            console.log("Unauthorized. Redirecting to sign-in.");

            if (!toastShown) {
              toast.error(`${t('session_expired_error_msg')}`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
              toastShown = true; // Set the flag to true to prevent future toasts
            }
            setTimeout(() => {
              dispatch(logout());
              history.push('/auth/sign-in');
              // alert("loggedout")
            }, 2000);
          } else {
            console.log("Error fetching statistics:", res.error);

          }
        } else {
          // Process the response here, for example:
          const { sessions_today, sessions_this_week } = res.data;
          // console.log("Statistics fetched:", sessions_today, sessions_this_week);
        }
      } catch (err) {
        console.log("Error during fetch:", err);

      }
    };

    if (accessToken) {
      fetchStatisticsTodayAndWeek();
    }
  }, [accessToken, dispatch, history]);

  // const handleCheckboxChange = (serviceName) => {
  //   setFormData((prev) => {
  //     const currentSpecialties = prev.consultation_specialities.map(service => service.name);
  //     const newSpecialties = currentSpecialties.includes(serviceName)
  //       ? prev.consultation_specialities.filter(service => service.name !== serviceName) // Remove service if already checked
  //       : [...prev.consultation_specialities, { name: serviceName }]; // Add service if not checked
  //     console.log("newSpecialties", newSpecialties)
  //     return {
  //       ...prev,
  //       consultation_specialities: newSpecialties,
  //     };
  //   });
  //   setHasChanged(true);
  // };

  // IF ID is there then use this
  const handleCheckboxChange = (serviceId) => {
    setFormData((prev) => {
      const currentSpecialties = prev.consultation_specialities.map(service => service.id);
      const newSpecialties = currentSpecialties.includes(serviceId.id)
        ? prev.consultation_specialities.filter(service => service.id !== serviceId.id) // Remove service if already checked
        : [...prev.consultation_specialities, serviceId]; // Add service if not checked

      // Concatenate ids as a comma-separated string
      const concatenatedIds = newSpecialties.map(service => service.id).join(',');
      // console.log("Concatenated IDs:", concatenatedIds);
      setSelectedServicesIds(concatenatedIds)
      // console.log("newSpecialties", newSpecialties)
      return {
        ...prev,
        consultation_specialities: newSpecialties,
      };
    });
    setHasChanged(true);
  };


  const handleChange = (fileOrEvent, field) => {
    if (typeof fileOrEvent === 'object' && fileOrEvent.target) {
      // Handle regular input changes
      const { id, value } = fileOrEvent.target;
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    } else {
      // Handle file upload changes (for image and signature)
      setFormData((prev) => ({
        ...prev,
        [field]: fileOrEvent,
      }));
    }
    setHasChanged(true);
  };

  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // console.log("formData", formData);

  const handleSubmit = async () => {
    const dataToSubmit = {};
    // console.log("form data", formData)
    // First Name

    if (formData.firstName) {
      dataToSubmit.first_name = formData.firstName;
    }

    // Last Name

    if (formData.lastName) {
      dataToSubmit.last_name = formData.lastName;
    }


    // About
    if (i18n.language === 'fr') {
      if (formData.about) {
        dataToSubmit.about_fr = formData.about;
      }
    } else {
      if (formData.about) {
        dataToSubmit.about = formData.about;
      }
    }

    // Designation
    if (i18n.language === 'fr') {
      if (formData.designation) {
        dataToSubmit.designation_fr = formData.designation;
      }
    } else {
      if (formData.designation) {
        dataToSubmit.designation = formData.designation;
      }
    }

    // Gender
    if (formData.gender) {
      dataToSubmit.gender = formData.gender;
    }
    // preferred Language
    if (formData.preferredLanguage) {
      dataToSubmit.preferred_language = formData.preferredLanguage;
    }

    // Address
    if (formData.address) {
      dataToSubmit.address = formData.address;
    }

    // Postal Code
    if (formData.postalCode) {
      dataToSubmit.postal_code = formData.postalCode;
    }

    // Country Code
    if (formData.countryCode) {
      dataToSubmit.country_code = formData.countryCode;
    }

    // Phone Number
    if (formData.phoneNumber) {
      dataToSubmit.phone_number = formData.phoneNumber;
    }
    // Handle selected services
    if (formData.consultation_specialities) {
      // Map the service objects to their IDs and create an array
      const consultationSpecialitiesArray = formData.consultation_specialities.map(service => service.id);

      // Append each service ID with an indexed key
      consultationSpecialitiesArray.forEach((id, index) => {
        dataToSubmit[`consultation_specialities[${index}]`] = id;
      });
    }



    // Only append image files if they have been changed
    if (formData.image !== initialImage && formData.image) {
      dataToSubmit.image = dataURLtoFile(formData.image, 'image.png');
    } else {
      delete formData.image;
    }
    if (formData.signature_image !== initialSignatureImage && formData.signature_image) {
      dataToSubmit.signature_image = dataURLtoFile(formData.signature_image, 'signature.png');
    } else {
      delete formData.signature_image;
    }

    // Check if any data is available to submit
    if (Object.keys(dataToSubmit).length === 0) {
      console.log('No data to submit.');
      return;
    }

    // Call your API with the dataToSubmit
    // console.log('Submitting data:', dataToSubmit);

    try {
      // Await the API call to ensure it resolves before continuing
      const res = await rdvService.profileUpdate(accessToken, dataToSubmit);

      // console.log("Response from API call:", res);
      // Dispatch the loginSuccess action to update only professional_profile
      dispatch(
        loginSuccess({
          accessToken, // Keep the same accessToken
          user, // Keep the same user
          professional_profile: res.data, // Update with new professional_profile
          association, // Keep the same association
        })
      );
      setInitialImage(res.data?.image || '');
      setInitialSignatureImage(res.data?.signature_image || '');

      // Show success toast with custom theme
      toast.success(`${t('mediationTab.profile_updated_success')}`, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored" // Use the colored theme
      });

    } catch (error) {
      console.error('Error updating profile:', error);
      // Show error toast with custom theme
      toast.error(`${t('mediationTab.profile_update_error')}`, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored" // Use the colored theme
      });
    } finally {
      // Ensure loading state is managed appropriately
      // setLoading(false); // Uncomment this if you're managing a loading state
      setHasChanged(false);
    }
  };

  const tableHeaderStyles = {
    textAlign: "start",
    fontSize: "16px",
    fontWeight: "600",
    color: "#252525",
  };

  const tableContentStyles = {
    fontSize: "16px",
    fontWeight: "400",
    color: "#565656",
  };
  return (
    <Box p={6} pt={2}>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button colorScheme="red" onClick={handleSubmit} disabled={!hasChanged}>
          {t("admin__appointments_table__update_participant")}
        </Button>
      </Box>

      <Box pb={5}>
        {/* First Name */}
        <FormControl isRequired paddingY={2}>
          <FormLabel htmlFor="firstName">{t("common__first_name")}</FormLabel>
          <Input
            id="firstName"
            placeholder={t("enter_first_name")}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
            value={formData?.firstName}
            onChange={handleChange}
          />
        </FormControl>

        {/* Last Name */}
        <FormControl isRequired paddingY={2}>
          <FormLabel htmlFor="lastName">{t("common__last_name")}</FormLabel>
          <Input
            id="lastName"
            placeholder={t("enter_last_name")}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
            value={formData?.lastName}
            onChange={handleChange}
          />
        </FormControl>

        {/* Gender */}
        <FormControl isRequired width="100%" paddingY={2}>
          <FormLabel htmlFor="gender" width="100%">
            {t("admin__appointments_table__gender")}
          </FormLabel>
          <Select
            id="gender"
            placeholder={t("select_gender")}
            value={formData.gender}
            onChange={handleChange}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
            textTransform="capitalize"
          >
            {/* <option value="male">{t("genders.male")}</option>
            <option value="female">{t("genders.female")}</option>
            <option value="prefer-not-to-say">{t("genders.preferNotSay")}</option> */}
            <option value="She/Her/Hers">{t("pronouns.she_her_hers")}</option>
            <option value="He/Him/His">{t("pronouns.he_him_his")}</option>
            <option value="Ze/Hir/Hirs">{t("pronouns.ze_hir_hirs")}</option>
            <option value="They/Them/Theirs">{t("pronouns.they_them_theirs")}</option>
            <option value="No Pronoun">{t("pronouns.no_pronoun")}</option>
            <option value="No Preference">{t("pronouns.no_preference")}</option>
            <option value="Not Listed">{t("pronouns.not_listed")}</option>
          </Select>
        </FormControl>

        {/* Preferred Language */}
        <FormControl isRequired width="100%" paddingY={2}>
          <FormLabel htmlFor="preferredLanguage" width="100%">
            {t("admin__appointments_table__preferred_language")}
          </FormLabel>
          <Select
            id="preferredLanguage"
            placeholder={t("select_language")}
            value={formData.preferredLanguage}
            onChange={handleChange}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
            textTransform="capitalize"
          >
            <option value="en">{t("languages.english")}</option>
            <option value="fr">{t("languages.french")}</option>
          </Select>
        </FormControl>

        {/* serviecs */}
        <FormControl isRequired width="100%" paddingY={2}>
          <FormLabel htmlFor="services" width="100%">
            {t("services_profile")}
          </FormLabel>
          {selectedServices.map((data, index) => (
            <Box key={index}>
              <Checkbox
                isChecked={Array.isArray(formData.consultation_specialities) &&
                  formData.consultation_specialities.some(service => service.name === data.name)}
                onChange={() => handleCheckboxChange(data)} // Call the handler here
                css={{
                  '.chakra-checkbox__control': {
                    borderColor: '#CBD5E0',  // Default border color (light grey)
                    transition: 'background-color 0.3s, border-color 0.3s',  // Smooth transition for background and border
                    padding: '0.25rem',  // Extra padding for better click area
                  },
                  '.chakra-checkbox__control:hover': {
                    backgroundColor: '#F7FAFC',  // Light background on hover
                    borderColor: '#FF6666',  // Border color changes to match the checked state
                  },
                  '.chakra-checkbox__control[data-checked]': {
                    backgroundColor: '#FF6666',  // Checked background color
                    borderColor: '#FF6666',  // Checked border color
                    color: 'white',
                  },
                  '.chakra-checkbox__control[data-checked]:hover': {
                    backgroundColor: '#cc3f3f',  // Darker background on hover when checked
                  },
                  '.chakra-checkbox__control[aria-disabled="true"]': {
                    backgroundColor: '#FF6666',
                    borderColor: '#FF6666',
                    opacity: 0.6,  // Slight transparency for disabled state
                    cursor: 'not-allowed',
                    color: 'white',
                  },
                  '.chakra-checkbox__control:focus': {
                    boxShadow: '0 0 0 2px rgba(230, 78, 100, 0.4)',  // Focus ring for better accessibility
                  },
                }}
              >
                {i18n.language === 'en' ? data.name : data.name_fr}
              </Checkbox>
            </Box>
          ))}

          {/* <Box>
            <Checkbox
              isChecked={Array.isArray(formData.consultation_specialities) &&
                formData.consultation_specialities.some(service => service.name === t("services.family_mediation"))}
              value={t("services.family_mediation")}
              onChange={() => handleCheckboxChange(t("services.family_mediation"))} // Call the handler here
              css={{
                '.chakra-checkbox__control': {
                  borderColor: '#CBD5E0',  // Default border color (light grey)
                  transition: 'background-color 0.3s, border-color 0.3s',  // Smooth transition for background and border
                  padding: '0.25rem',  // Extra padding for better click area
                },
                '.chakra-checkbox__control:hover': {
                  backgroundColor: '#F7FAFC',  // Light background on hover
                  borderColor: '#FF6666',  // Border color changes to match the checked state
                },
                '.chakra-checkbox__control[data-checked]': {
                  backgroundColor: '#FF6666',  // Checked background color
                  borderColor: '#FF6666',  // Checked border color
                  color: 'white',
                },
                '.chakra-checkbox__control[data-checked]:hover': {
                  backgroundColor: '#cc3f3f',  // Darker background on hover when checked
                },
                '.chakra-checkbox__control[aria-disabled="true"]': {
                  backgroundColor: '#FF6666',
                  borderColor: '#FF6666',
                  opacity: 0.6,  // Slight transparency for disabled state
                  cursor: 'not-allowed',
                  color: 'white',
                },
                '.chakra-checkbox__control:focus': {
                  boxShadow: '0 0 0 2px rgba(230, 78, 100, 0.4)',  // Focus ring for better accessibility
                },
              }}
            >
              {t("services.family_mediation")}
            </Checkbox>
          </Box>
          <Box>
            <Checkbox
              isChecked={Array.isArray(formData.consultation_specialities) &&
                formData.consultation_specialities.some(service => service.name === t("services.support_for_adolescents"))}
              value={t("services.support_for_adolescents")}
              onChange={() => handleCheckboxChange(t("services.support_for_adolescents"))} // Call the handler here
              css={{
                '.chakra-checkbox__control': {
                  borderColor: '#CBD5E0',  // Default border color (light grey)
                  transition: 'background-color 0.3s, border-color 0.3s',  // Smooth transition for background and border
                  padding: '0.25rem',  // Extra padding for better click area
                },
                '.chakra-checkbox__control:hover': {
                  backgroundColor: '#F7FAFC',  // Light background on hover
                  borderColor: '#FF6666',  // Border color changes to match the checked state
                },
                '.chakra-checkbox__control[data-checked]': {
                  backgroundColor: '#FF6666',  // Checked background color
                  borderColor: '#FF6666',  // Checked border color
                  color: 'white',
                },
                '.chakra-checkbox__control[data-checked]:hover': {
                  backgroundColor: '#cc3f3f',  // Darker background on hover when checked
                },
                '.chakra-checkbox__control[aria-disabled="true"]': {
                  backgroundColor: '#FF6666',
                  borderColor: '#FF6666',
                  opacity: 0.6,  // Slight transparency for disabled state
                  cursor: 'not-allowed',
                  color: 'white',
                },
                '.chakra-checkbox__control:focus': {
                  boxShadow: '0 0 0 2px rgba(230, 78, 100, 0.4)',  // Focus ring for better accessibility
                },
              }}
            >
              {t("services.support_for_adolescents")}
            </Checkbox>
          </Box>
          <Box>
            <Checkbox
              isChecked={Array.isArray(formData.consultation_specialities) &&
                formData.consultation_specialities.some(service => service.name === t("services.supporting_parenthood"))}
              value={t("services.supporting_parenthood")}
              onChange={() => handleCheckboxChange(t("services.supporting_parenthood"))} // Call the handler here
              css={{
                '.chakra-checkbox__control': {
                  borderColor: '#CBD5E0',  // Default border color (light grey)
                  transition: 'background-color 0.3s, border-color 0.3s',  // Smooth transition for background and border
                  padding: '0.25rem',  // Extra padding for better click area
                },
                '.chakra-checkbox__control:hover': {
                  backgroundColor: '#F7FAFC',  // Light background on hover
                  borderColor: '#FF6666',  // Border color changes to match the checked state
                },
                '.chakra-checkbox__control[data-checked]': {
                  backgroundColor: '#FF6666',  // Checked background color
                  borderColor: '#FF6666',  // Checked border color
                  color: 'white',
                },
                '.chakra-checkbox__control[data-checked]:hover': {
                  backgroundColor: '#cc3f3f',  // Darker background on hover when checked
                },
                '.chakra-checkbox__control[aria-disabled="true"]': {
                  backgroundColor: '#FF6666',
                  borderColor: '#FF6666',
                  opacity: 0.6,  // Slight transparency for disabled state
                  cursor: 'not-allowed',
                  color: 'white',
                },
                '.chakra-checkbox__control:focus': {
                  boxShadow: '0 0 0 2px rgba(230, 78, 100, 0.4)',  // Focus ring for better accessibility
                },
              }}
            >
              {t("services.supporting_parenthood")}
            </Checkbox>
          </Box> */}
        </FormControl>

        {/* Designation */}
        <FormControl isRequired paddingY={2}>
          <FormLabel htmlFor="designation">{t("common__designation")}</FormLabel>
          <Input
            id="designation"
            placeholder={t("enter_your_designation")}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
            value={formData.designation}
            onChange={handleChange}
          />
        </FormControl>

        {/* About */}
        <FormControl isRequired paddingY={2}>
          <FormLabel htmlFor="about">{t("common__about")}</FormLabel>
          <Textarea
            id="about"
            placeholder={t("tell_us_about_yourself")}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
            value={formData.about}
            onChange={handleChange}
            resize="vertical"
            minHeight="100px"
          />
        </FormControl>

        {/* Address, Country Code, Phone Number */}
        <Flex direction="row" wrap="wrap" gap={4} paddingY={2}>
          {/* Address */}
          <FormControl isRequired flex="1" minWidth="200px">
            <FormLabel htmlFor="address">{t("admin__location_common__address")}</FormLabel>
            <Input
              id="address"
              placeholder={t("enter_address")}
              variant="filled"
              bg="#FAFAFA"
              borderColor="#E7E7E7"
              _hover={{ borderColor: '#E7E7E7' }}
              _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
              value={formData.address}
              onChange={handleChange}
            />
          </FormControl>

          {/* Postal Code */}
          <FormControl isRequired flex="1" minWidth="200px">
            <FormLabel htmlFor="postalCode">{t("admin__location_common__postal_code")}</FormLabel>
            <Input
              id="postalCode"
              placeholder={t("enter_postal_code")}
              variant="filled"
              bg="#FAFAFA"
              borderColor="#E7E7E7"
              _hover={{ borderColor: '#E7E7E7' }}
              _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
              value={formData.postalCode}
              onChange={handleChange}
            />
          </FormControl>

          {/* Country Code */}
          <FormControl isRequired flex="1" minWidth="200px">
            <FormLabel htmlFor="countryCode">{t("admin__country_code")}</FormLabel>
            <Input
              id="countryCode"
              placeholder={t("enter_country_code")}
              variant="filled"
              bg="#FAFAFA"
              borderColor="#E7E7E7"
              _hover={{ borderColor: '#E7E7E7' }}
              _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
              value={formData.countryCode}
              onChange={handleChange}
            />
          </FormControl>

          {/* Phone Number */}
          <FormControl isRequired flex="1" minWidth="200px">
            <FormLabel htmlFor="phoneNumber">{t("new_request_form.phone_number")}</FormLabel>
            <Input
              id="phoneNumber"
              placeholder={t("enter_phone_number")}
              variant="filled"
              bg="#FAFAFA"
              borderColor="#E7E7E7"
              _hover={{ borderColor: '#E7E7E7' }}
              _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </FormControl>
        </Flex>

        {/* Image and Signature Upload Section */}
        <Box mt={8}>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {t("mediationTab.images")}
          </Text>
          <Flex
            gap={{ base: 4, md: 8 }}
            justify="space-evenly"
            flexDirection={{ base: 'column', md: 'row' }} // Stack in column for smaller screens
          >
            {/* Mediator Image Section */}
            <Box

              textAlign="center"
              maxW="300px"
              minW="300px"
              mx="auto"
            >
              <UploadBox
                label={t("mediationTab.mediator_image")}
                image={formData?.image}
                altText={t("mediationTab.mediator_image_alt")}
                noImageText={t("mediationTab.no_image")}
                onUpload={(file) => handleChange(file, 'image')}
              />
              <Text mt={2} fontSize="lg" fontWeight="bold" color="gray.700">
                {t("mediationTab.profile_image_heading")}
              </Text>
            </Box>

            {/* Mediator Signature Section */}
            {/* <Box

              textAlign="center"
              maxW="300px"
              minW="300px"
              mx="auto"
            >
              <UploadBox
                label={t("mediationTab.mediator_signature")}
                image={formData?.signature_image}
                altText={t("mediationTab.mediator_signature_alt")}
                noImageText={t("mediationTab.no_signature")}
                onUpload={(file) => handleChange(file, 'signature_image')}
                isSignature
              />
              <Text mt={2} fontSize="lg" fontWeight="bold" color="gray.700">
                {t("mediationTab.signature_image_heading")}
              </Text>
            </Box> */}
          </Flex>
        </Box>
      </Box>

      {/* Location Table */}
      <Box borderWidth="1px" borderRadius="lg" overflow="auto" width='100%' marginY={3} >
        <Flex justify="space-between" p={4} borderBottomWidth="1px">
          <Text fontSize="2xl" fontWeight="semibold">{t("admin__header__locations")}</Text>
          {/* <Button colorScheme="red" variant="outline" onClick={handleOpenModal}>
              {t("admin__add_location")}
            </Button> */}
        </Flex>

        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th sx={tableHeaderStyles}>{t("admin__location_name")}</Th>
                <Th sx={tableHeaderStyles}>{t("admin__appointments_table__address")}</Th>
                <Th sx={tableHeaderStyles}>{t("zip_code")}</Th>
                <Th sx={tableHeaderStyles}>{t("admin__appointments_table__department")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {professional_profile && professional_profile?.locations.map((p) => (
                <Tr>
                  <Td sx={tableContentStyles} maxWidth='200px'>
                    {p.name.length > 45
                      ? `${p.name.substring(0, 50)}...`
                      : p.name}
                  </Td>
                  <Td sx={tableContentStyles} maxWidth='200px'>
                    {p.address.length > 55
                      ? `${p.address.substring(0, 60)}...`
                      : p.address}
                  </Td>
                  <Td sx={tableContentStyles}>{p.zip_code ? p.zip_code : 'N/A'}</Td>
                  <Td sx={tableContentStyles}>{p.zip_code?.split("")[0] + p.zip_code?.split("")[1]}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

      </Box>

      {/* Location Modal */}
      {/* {isModalOpen && <LocationModal onClose={handleCloseModal} />} */}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  professional_profile: selectProfessionalProfile(state)
})

export default connect(mapStateToProps)(Index);